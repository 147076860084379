(function() {
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

if(!window.__ab2__) {
    window.__ab2__ = {};
}

window.__ab2__.sendAb2 = function (config, type) {
    Abq._init(config);
    Abq._send(type);
};

function Statistic() {}

Statistic.prototype = {
    constructor: Statistic,
    UNKNOW: '',
    _objStr: Object.prototype.toString(),
    _init: function _init(config) {
        // 初始化；
        try {
            this._config = config;
            this._getPropagationClick(config.event || '', config.abq || '', config.value || '');
        } catch (e) {
            throw 'the config option is not defined, please make sure the config is existed';
        }
    },
    _businessParams: function _businessParams(params) {
        var config = this._config.business;
        config.ev ? config.ev = config.ev : null;
        config.ds ? config.ds = config.ds : null;
        config.pl ? config.pl = config.pl : null;
        if ((typeof config === 'undefined' ? 'undefined' : _typeof(config)) !== 'object') {
            throw "please make sure the type of params key 'business' is object !";
        }
        return this._assign(config, params);
    },
    getUrl: function getUrl() {
        if (this._config.getUrl) {
            return (window.location.protocol === 'https:' ? 'https://' : 'http://') + this._config.getUrl + '?';
        } else {
            throw "the 'getUrl' is not defined in config, please make sure it is existed";
        }
    },
    postUrl: function postUrl() {
        if (this._config.postUrl) {
            return (window.location.protocol === 'https:' ? 'https://' : 'http://') + this._config.postUrl;
        } else {
            throw "the 'postUrl' is defined in config, please make sure it is existed";
        }
    },
    _getUa: function _getUa() {
        var ua = navigator.userAgent,
            isWindowsPhone = /(?:Windows Phone)/.test(ua),
            isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
            isAndroid = /(?:Android)/.test(ua),
            isFireFox = /(?:Firefox)/.test(ua),
            isChrome = /(?:Chrome|CriOS)/.test(ua),
            isTablet = /(?:iPad|PlayBook)/.test(ua) || isAndroid && !/(?:Mobile)/.test(ua) || isFireFox && /(?:Tablet)/.test(ua),
            isPhone = /(?:iPhone)/.test(ua) && !isTablet,
            isPc = !isPhone && !isAndroid && !isSymbian;
        return {
            isTablet: isTablet,
            isPhone: isPhone,
            isAndroid: isAndroid,
            isPc: isPc
        };
    },
    _getCookie: function _getCookie(name) {
        var reg = new RegExp(name + '\s*=\s*([^\;]+)\;*'),
            result = reg.exec(document.cookie);
        return result && result.length > 1 ? result[1] : this.UNKNOW;
    },
    getHref: window.location.href,
    getRef: document.referrer,
    getSc: window.screen.width + '*' + window.screen.height,
    _getUrlParams: function _getUrlParams(name) {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i'),
            r = window.location.search.substr(1).match(reg);
        if (r !== null) {
            return unescape(r[2]);
        }
        return null;
    },
    // 第一个参数是Ua里的名称；第二个参数是Url里的参数名称；
    _getAppVersion: function _getAppVersion() {
        var appVersion = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'xinapp';
        var appVerForUrl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'appver';

        var ua = window.navigator.userAgent;
        ua = ua.toLowerCase();
        var version = new RegExp(appVersion + '*[\/,=]\s*([^\s]+)').exec(ua);

        return version && version.length > 1 ? version[1].split(' ')[0] : this._getUrlParams(appVerForUrl) || '';
    },

    _getAppId: function _getAppId() {
        var appId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'app_id';

        // Url获取，没有则空；
        return this._getUrlParams(appId) || '';
    },
    _getXdid: function _getXdid() {
        var xdid = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'xdid';

        // Ua获取，没有则空；
        return this._getUrlParams(xdid) ? this._getUrlParams(xdid).toLowerCase() : '';
    },
    // cookie里的uid
    _getUid: function _getUid() {
        var uid = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'uid';
        var uidForUrl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'uid';

        return this._getCookie(uid) || this._getUrlParams(uidForUrl) || '';
    },
    // cookie里的设备编号，大部分是：'XIN_UID_CK',新车直租是：'brid'
    _getCid: function _getCid() {
        var cid = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'XIN_UID_CK';

        return this._getCookie(cid) || '';
    },
    // cookie里的城市定位，第一个参数cookie里的定位字段，第二个参数是cookie里定位字段的城市id，第三个参数是url中的城市id；
    _getCity: function _getCity() {
        var cityIdForCookie = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'XIN_LOCATION_CITY';
        var cityId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'cityid';
        var cityIdForUrl = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'cityid';

        var cityData = this._getCookie(cityIdForCookie);
        if (cityData) {
            try {
                return _typeof(JSON.parse(decodeURIComponent(cityData))) === 'object' ? JSON.parse(decodeURIComponent(cityData))[cityId] : JSON.parse(decodeURIComponent(cityData));
            } catch (e) {
                return (typeof cityData === 'undefined' ? 'undefined' : _typeof(cityData)) === 'object' ? cityData[cityId] : cityData;
            }
        } else {
            return this._getUrlParams(cityIdForUrl) || -1;
        }
    },
    _getPlatform: function _getPlatform() {
        var UA = this._getUa(),
            config = this._config,
            match3w = this.getHref.match(/:\/\/([A-Za-z]*)\./),
            devHrefMatch3w = this.getHref.split('.') ? this.getHref.split('.').indexOf('www')>-1 : false;
        return UA.isPhone && this._getAppVersion(config.appVersion, '') ? 'm-ios' : UA.isAndroid && this._getAppVersion(config.appVersion, '') ? 'm-android' : UA.isPc && ((match3w && match3w.indexOf('www')>-1) || devHrefMatch3w) ? 'www' : 'm';
    },
    _getPageId: function _getPageId() {
        return window.PAGE_ID || window.location.href.split("?")[0];
    },
    _getSource: function _getSource() {
        var source = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'source';

        return this._getUrlParams(source) || '';
    },
    _getChannel: function _getChannel() {
        var channel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'channel';

        return this._getUrlParams(channel) || '';
    },
    _getAbversion: function _getAbversion() {
        var abversion = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'abversion';

        return this._getUrlParams(abversion) || '';
    },
    _getNet:function _getNet () {
        var net = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'net';
        return this._getUrlParams(net) || '';
    },
    _getLocation:function _getLocation () {
        var location = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'location';
        return this._getUrlParams(location) || '';
    },
    _assign: function _assign(o, n) {
        for (var p in n) {
            if (n.hasOwnProperty(p)) o[p] = n[p];
        }
        return o;
    },
    _getShopId:function _getNet (config) {
        var cityid = this._getCity(config.cityIdForCookie, config.cityId, config.cityIdForUrl);
        var shopMs = decodeURIComponent(this._getCookie("s"+cityid)) || '';
        var shopId = "";
        if(shopMs){
            shopId = JSON.parse(shopMs).id || '';
        }
        return shopId;

    },
    _getOptions: function _getOptions() {
        var config = this._config,
            params = {
                app: this._getAppVersion(config.appVersion, config.appVerForUrl),
                uid: this._getUid(config.uid, config.uidForUrl),
                cid: this._getCid(config.cid),
                siteid: this._getShopId(config),
                cityid: this._getCity(config.cityIdForCookie, config.cityId, config.cityIdForUrl),
                platform: this._getPlatform(),
                source: this._getSource(config.source),
                channel: this._getChannel(config.channel),
                sc: this.getSc,
                abversion: this._getAbversion(config.abversion),
                pageid: this._getPageId(),
                url: encodeURIComponent(this.getHref),
                ref: encodeURIComponent(this.getRef) === 'undefined' ? '' : encodeURIComponent(this.getRef),
                app_id: this._getAppId(config.appId),
                xdid: this._getXdid(config.xdid),
                ts: new Date().getTime(),
                net: this._getNet(config.net),
                location: this._getLocation(config.location)
            },
            business = this._businessParams();
        return this._assign(params, business);
    },
    _collectAllOptionsForGet: function _collectAllOptionsForGet() {
        var str = '',
            params = this._getOptions();
        for (var key in params) {
            str = str + key + '=' + params[key] + '&';
        }
        return str.substring(0, str.length - 1);
    },
    // _collectAllOptionsForPost: function _collectAllOptionsForPost() {
    //     var formData = new FormData(),
    //         params = this._getOptions();
    //     for (var key in params) {
    //         formData.append(key, params[key]);
    //     }
    //     return formData;
    // },
    _ajaxPost: function _ajaxPost(data) {
        var success = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this._config.success;
        var failed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this._config.failed;

        var xhr = null;
        if (window.XMLHttpRequest) {
            xhr = new XMLHttpRequest();
        } else {
            xhr = new ActiveXObject('Microsoft.XMLHTTP');
        };
        xhr.open('POST', this.postUrl(), true);
        xhr.setRequestHeader("Content-type", "multipart/form-data");
        xhr.send(data);

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 403) {
                    success(xhr.responseText);
                } else {
                    if (failed) {
                        failed(xhr.status);
                        throw 'this request is failed，the status is ' + xhr.status + 'please try again！';
                    }
                }
            }
        };
    },
    _ajaxGet: function _ajaxGet(data) {
        var img = new Image();
        img.style.display = 'none';
        img.src = this.getUrl() + data;
        document.body.appendChild(img);
    },
    _getPropagationClick: function _getPropagationClick() {
        var event = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'event';
        var abq = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'ABQ';
        var value = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'value';

        document.addEventListener('click', function (e) {
            if (e.target.getAttribute(event) === abq) {
                if (!e.target.getAttribute(value)) {
                    throw "the attribute 'value' is not defined,please make sure the value is existed";
                } else {
                    var params = e.target.getAttribute(value);
                    if ((typeof params === 'undefined' ? 'undefined' : _typeof(params)) === 'object') {
                        params.type = 'c';
                        this._businessParams(params);
                        this._send('post');
                    } else {
                        throw "the attribute 'value' is must be a object, the key is params key, the value is params value.";
                    }
                }
            }
        }, false);
    },
    _send: function _send() {
        var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'post';

        var getParams = this._collectAllOptionsForGet();
        type === 'post' ? this._ajaxGet(getParams) : this._ajaxGet(getParams);
    }
};

var Abq = new Statistic();
})();